<template>
  <div v-show="dialog" class="relative z-50">
    <div class="fixed inset-0 bg-black bg-opacity-80"></div>
    <div class="fixed inset-0 overflow-auto">
      <div class="flex items-center justify-center h-full">
        <div class="bg-white rounded-xl w-full max-w-sm md:max-w-md lg:max-w-md">
          <div>
            <div class="flex font-bold justify-between rounded-t-xl bg-primary text-white p-4">
              <h3>Confirmation</h3>
              <button @click="cancel"><font-awesome-icon :icon="['fasr', 'xmark']" size="xl" /></button>
            </div>
            <div class="p-6">
              <div class="flex flex-col gap-1 text-lg">
                <div class="grid">
                  <span>Name</span>
                  <span class="font-semibold">{{ data.name }}</span>
                </div>
                <div class="grid">
                  <span>Phone</span>
                  <span class="font-semibold">{{ data.phone }}</span>
                </div>
                <hr class="border-gray-300 my-1" />
                <div class="grid">
                  <span>Reference No</span>
                  <span class="font-semibold">{{ data.reference_no }}</span>
                </div>
                <hr class="border-gray-300 my-1" />
                <div class="grid">
                  <span>Payment Method</span>
                  <span class="font-semibold">{{ data.vendor }}</span>
                </div>
                <hr class="border-gray-300 my-1" v-if="data.vendor == 'OTHER'" />
                <div class="grid" v-if="data.vendor == 'OTHER'">
                  <span>Expired Date</span>
                  <span class="font-semibold">{{ data.expired_at }}</span>
                </div>
                <hr class="border-gray-300 my-1" />
                <div class="flex justify-between">
                  <span>Amount</span>
                  <span class="text-primary font-semibold">{{ data.amount | toCurrency }}</span>
                </div>
                <hr class="border-gray-300 my-1" />
                <div class="flex justify-between">
                  <span>Admin Fee</span>
                  <span class="text-primary font-semibold">{{ data.switching_fee | toCurrency }}</span>
                </div>
                <hr class="border-gray-300 my-1" />
                <div class="flex justify-between">
                  <span class="font-bold">Total</span>
                  <span class="text-primary font-bold">{{ data.payment_amount | toCurrency }}</span>
                </div>
                <hr class="border-gray-300 my-1" />
              </div>
              <div class="w-full mt-10">
                <a :href="data.payment_redirect_url" v-if="data.vendor == 'OTHER'" @click="cancel">
                  <div class="w-full text-center bg-primary text-white font-semibold py-2 rounded-lg">Pay Now</div>
                </a>
                <button v-else @click="confirm" class="w-full text-center bg-primary text-white font-semibold py-2 rounded-lg">
                  Top Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalInquiry",
  components: {
  },
  props: {
    dialog: Boolean,
    data: {
      required: true,
      type: Object
    },
  },
  computed: {
    textButton(){
      return 'Pay Now';
    }
  },
  methods: {
    async confirm() {
        this.$emit("convertBalance");
    },
    cancel() {
      this.$emit("closeDialog");
    },
  },
};
</script>
